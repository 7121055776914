
.submenu {
    position: relative;
}

.submenu-img {
    width: 100%;
}

.submenu-name {
    font-family: 'ninja_narutoregular';
    position: absolute;
    top: 20%;
    width: 100%;
    text-align: center;
    font-size: 5em;
}

.submenu-links {
    position: absolute;
    bottom: 20%;
    width: 100%;
    text-align: center;
    font-size: 2em;
}

.submenu-link {
    font-family: 'night_in_tokyoregular';
    margin: 1em;
}

@media only screen and (max-width: 1020px) {
    .submenu-name {
        display: none;
    }
    .submenu-links {
        display: grid;
        grid-template-columns: auto;
    }
    .submenu-link {
        font-size: 2em;
        margin: .3em;
    }
}

@media only screen and (max-width: 600px) {
    .submenu-name {
        display: none;
    }
    .submenu-links {
        display: grid;
        grid-template-columns: auto;
    }
    .submenu-link {
        font-size: 2em;
        margin: .3em;
    }
}