
.menubar-list {
    list-style-type: none;
    display: flex;
    padding: 1em 0 1em 0;
}

.menubar-item {
    text-align: center;
    margin: auto;
}

.menubar-img {
    margin: auto;
}

.menubar-text {
    margin: 0;
    font-size: 1.8em;
    font-family: 'night_in_tokyoregular';
}

@media only screen and (max-width: 1020px) {
    .menubar-item {
        min-width: 15%;
    }
    .menubar-list {
        overflow-x: scroll;
    }
}

@media only screen and (max-width: 600px) {
    .menubar-item {
        min-width: 25%;
    }
    .menubar-list {
        overflow-x: scroll;
    }
}