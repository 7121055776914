.contact {
    font-family: 'Comic Sans MS';
    display: grid;
}

.opentime-text {
    font-size: 2em;
    text-align: center;
    grid-column: 1 / span 2;
    grid-row: 1;
}

.contact-map {
    width: 100%;
    grid-column: 1;
    grid-row: 2;
    margin: auto;
}

.map {
    width: 100%;
    height: 400px;
    border: 0;
}

.contact-text {
    text-align: center;
    grid-column: 2;
    grid-row: 2;
    padding: 2em;
}

.requisiten {
    display: flex;
}

.requisiten-text {
    font-size: 1.5em;
}

@media only screen and (max-width: 600px) {
    .contact {
        display: block;
    }
    .requisiten {
        justify-content: center;
    }
    .requisiten-text {
        font-size: 2.5em;
    }
}