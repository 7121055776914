.news-marquee {
  overflow: hidden;
  white-space: nowrap;
  background-color: #333;
  color: #fff;
  font-family: 'Arial', sans-serif;
  font-size: 40px;
  line-height: 1.5;
}

.news-marquee-content {
  display: inline-block;
  padding-left: 50%;
  animation: news-marquee 10s linear infinite;
}

@keyframes news-marquee {
  from { transform: translateX(100%); }
  to { transform: translateX(-100%); }
}

@media (max-width: 768px) {
  .news-marquee-content {
    animation: news-marquee 10s linear infinite;
  }

  @keyframes news-marquee {
    from { transform: translateX(0); }
    to { transform: translateX(-300%); } /* Адаптируйте значение для мобильных устройств */
  }
}