
.item {
    border-style: outset;
    border-color: rgb(13, 13, 13);
    background-color: rgb(13, 13, 13);
    display: grid;
    grid-template-areas: 'name'
        'img'
        'img'
        'img'
        'img'
        'description'
        'description'
        'description'
        'description'
        'price';
    width: 95%;
    padding-bottom: 2em;
    margin: 0;
}

.item-name {
    grid-area: name;
    font-family: 'shojumaruregular';
    text-align: center;
    font-size: 2em;
}

.item-img {
    grid-area: img;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    height: auto;
}

.description-text {
    grid-area: description;
    font-family: 'pecitabook';
    text-align: center;
    font-size: 2em;
    margin-left: 1.5em;
    margin-right: 1.5em;
}

.item-price {
    grid-area: price;
    font-family: 'pecitabook';
    text-align: center;
    font-size: 2.5em;
    color: black;
    width: 40%;
    margin: auto;
    background-color: gold;
}

@media only screen and (max-width: 1020px) {
    .item {
        margin: auto;
    }
    .item-price{
        font-size: 3em;
    }
    .description-text {
        font-size: 2.5em;
    }
    .item-name {
        font-size: 3em;
    }
}

@media only screen and (max-width: 600px) {
    .item {
        margin: auto;
    }
    .item-price{
        font-size: 3em;
    }
    .description-text {
        font-size: 2.5em;
    }
    .item-name {
        font-size: 3em;
    }
}