html {
  min-height: 100vh;
}
body {
  /* background-image: url("http://localhost:3000/site_content_images/background.png"); */
  background-color: black;
  color: aliceblue;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

#root {
  min-height: 100vh;
}

.grid-container {
  display: grid;
  grid-template-areas: 'logo award navbar'
    'menubar menubar menubar'
    'news-marquee news-marquee news-marquee'
    'items items items'
    'footer footer footer';
  min-height: 100vh;
}

.news-marquee {
  grid-area: news-marquee;
  width: 100%;
  background-color: #000000;
  color: #fff;
}

.item1 {
  grid-area: logo;
}

.itemAward {
  grid-area: award;
}

.item2 {
  display: flex;
  grid-area: navbar;
  justify-content: center;
}

.item3 {
  grid-area: menubar;
  min-height: 8vh;
}

.item4 {
  grid-area: items;
  min-height: 60vh;
}

.items-grid {
  background-color: black;
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 1.5em;
}

.item5 {
  font-family: 'Comic Sans MS';
  grid-area: footer;
  min-height: 20vh;
}

.dr-dwn {
  display: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a:link {
    color: aliceblue;
    background-color: transparent;
    text-decoration: none;
  }
  
  a:visited {
    color: aliceblue;
    background-color: transparent;
    text-decoration: none;
  }
  
  a:hover {
    color: rgb(203, 197, 137);
    background-color: transparent;
    text-decoration: none;
  }
  
  a:active {
    color: gold;
    background-color: transparent;
    text-decoration: none;
  }

@media only screen and (max-width: 1020px) {
  body {
    zoom: 45%;
  }
  .grid-container {
    display: grid;
    grid-template-areas:'logo award dropdown'
      'navbar navbar navbar'
      'menubar menubar menubar'
      'news-marquee news-marquee news-marquee'
      'items items items'
      'footer footer footer';
    min-height: 100vh;
  }
  .items-grid{
    grid-template-columns: auto auto;
  }
  .dr-dwn{
    grid-area: dropdown;
    display: block;
    background-color: black;
    max-height: 60%;
    margin: auto;
  }
  .item2 {
    display: none;
  }
}


@media only screen and (max-width: 600px) {
  body {
    zoom: 25%;
  }
  .grid-container {
    display: grid;
    grid-template-areas: 'logo award dropdown'
      'navbar navbar navbar'
      'menubar menubar menubar'
      'news-marquee news-marquee news-marquee'
      'items items items'
      'footer footer footer';
    min-height: 100vh;
  }
  .items-grid{
    display: block;
  }
  .dr-dwn{
    grid-area: dropdown;
    display: block;
    background-color: black;
    max-height: 60%;
    margin: auto;
  }
  
  .item2 {
    display: none;
  }
}

