
.navbar-list {
    display: flex;
    width: 100%;
}

.navbar-item {
    text-align: center;
    margin: auto;
}

.navbar-text {
    font-family: 'shojumaruregular';
}


@media only screen and (max-width: 1020px) {
    .navbar-list {
        display: block;
    }
    .navbar-item {
        padding: 1em;
    }
    .navbar-text {
        font-size: 2em;
    }
}


@media only screen and (max-width: 600px) {
    .navbar-list {
        display: block;
    }
    .navbar-item {
        padding: 1em;
    }
    .navbar-text {
        font-size: 2em;
    }
}