
.item1 {
    display: flex;
}

.logo-text {
    color: gold;
    font-family: 'night_in_tokyoregular';
    margin: auto;
}
