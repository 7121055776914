.text {
    text-align: center;
    margin-left: 2em;
    margin-right: 2em;
    font-size: 1.75em;
}

@media only screen and (max-width: 600px) {
    .text {
        padding: 0.3em;
        font-size: 2.25em;
    }
}