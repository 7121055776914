
.leistungen-img {
    width: 100%;
    height: auto;
}

.leistungen-img-mobile {
    display: none;
}

@media only screen and (max-width: 600px) {
    .leistungen-img-mobile {
        display: block;
        width: 100%;
        height: auto;
    }
    .leistungen-img {
        display: none;
    }
}