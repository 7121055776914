
.aboutus-text {
    padding: 1em;
    text-align: center;
    font-family: 'Comic Sans MS';
    font-size: 1.5em;
    margin-bottom: 2em;
}

.aboutus-img {
    width: 100%;
    height: auto;
}

.aboutus-img-mobile {
    display: none;
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 600px) {
    .aboutus-img {
        display: none;
    }
    .aboutus-img-mobile {
        display: block;
    }
    .aboutus-text {
        font-size: 2.5em;
        padding: 0.5em;
    }
}